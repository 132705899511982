.services {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-title {
    font-size: 3.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    margin-top: 80px;
    padding: 0px;
}

.all-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.service {
    width: 30%;
    margin-left: 10%;
    margin-right: 10%;
}

.service img {
    width: 10%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.service-title {
    font-size: 1.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;

}

@media only screen and (max-width: 950px) {

    body {
        width: 100%;
        height: auto;

    }

    .services-title {
        font-size: 2.5rem;
        font-family: 'Raleway', 'Roboto';
        font-weight: 400;
        padding: 0px;
    }

    .all-services {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .service {
        width: 90%;
        margin-left: 20px;
        margin-right: 40px;
        margin-bottom: 40px;
    }

    .service img {
        width: 20%;
        height: auto;
        margin-top: 0px;
        margin-bottom: 20px;
    }
}