.position-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: auto;
    padding: 10px;
    background-color: white;
    color: black;
    font-family: 'Raleway', 'Roboto';
    font-size: 1.2rem;
    border-top: 1px solid #FE5967;
    border-bottom: 1px solid #FE5967;
    cursor: pointer;
}

.outer-position {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    margin: 10px;

}

.positions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 900px;
}

.position-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
}

.position-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #FE5967;
}

.position-block {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.position-detail-2{
    width: 80%;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.skills {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.position-applied {
    width: 100%;
}

.previous {
    width: 20%;
    color: black;
    font-size: 1.2rem;
    align-items: start;
    justify-content: start;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }