.footer {
    width: 100%;
    background-color: #FE5967;
    color: black;
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.footer-text {
    padding: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cnumber {
    color: black;
}

.cemail {
    color: black;
}

.empty-div {
    width: 100%;
    height: 250px;

}

@media only screen and (max-width: 950px) {

    body {
      width: 100%;
      height: auto;
  
    }

    .footer {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-text {
        padding: 10px;
    }
    
}