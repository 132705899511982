.home {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
}

.home img {
    width: 100%;
    height: 700px;
    margin-top: 30px;
}

.text-on-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.welcome {
    font-size: 4.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 700;
}

.home-2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

.home-title {
    font-size: 3.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    margin-top: 40px;
    padding: 0px;
}

.home-title-2 {
    font-size: 1.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    margin-top: -40px;
}

.home-para {
    font-size: 1rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    padding: 0px;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
}

.home-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
}

.home-3-p-1 {
    font-size: 2.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    margin-top: 0px;
    padding: 0px;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
}

.home-3-p-2 {
    font-size: 1rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    margin-top: 0px;
    padding: 0px;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
}

.home-4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.home-4-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 50px;
    font-family: 'Raleway', 'Roboto';
}

.home-4-div img {
    margin-bottom: 10px;
}

.home-5 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
    font-family: 'Raleway', 'Roboto';
}

.home-5-heading {
    font-size: 2.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    margin-top: 0px;
    padding: 0px;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;

}

.home-5-first {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 50px;
    font-family: 'Raleway', 'Roboto';
}

.img-all {
    width: 100px;
    height: 100px;
}

.home-5-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 50px;
    font-family: 'Raleway', 'Roboto';
    width: 40%;
    margin: 30px;
}

.img-talent {
    width: 150px;
    height: 70px;
}

@media only screen and (max-width: 950px) {

    body {
        width: 100%;
        height: auto;

    }

    .home-page {
        width: 100%;
    }

    .home-4 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }

    .home-5-first {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        font-family: 'Raleway', 'Roboto';
    }

    .home-5-heading {
        font-size: 2.5rem;
        font-family: 'Raleway', 'Roboto';
        font-weight: 400;
        margin-top: 0px;
        padding: 0px;
        text-align: center;
    }

    .home-5-div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 50px;
        font-family: 'Raleway', 'Roboto';
        width: 90%;
        margin: 30px;
        margin-top: 0px;
    }
}