.header {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    position: sticky;
    z-index: 1;
    background-color: white;
}

@media only screen and (max-width: 950px) {

    body {
        width: 100%;
        height: auto;

    }

    .header {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
    }

}