.navbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.nav-items {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
    font-size: 0.9rem;
    font-family: 'Raleway', 'Roboto';
    padding: 10px;
    font-weight: bold;
}

.nav-items-pending {
    padding: 10px;
    margin: 0px; 
    border-radius: 10px;
    color: black;
}

.nav-items a:hover {
    background-color: #FE5967;
    color: white;
}

.nav-items-active {
    background-color: #FE5967;
    color: white;
    padding: 10px;
    margin: 0px; 
    border-radius: 10px;
    color: white;
}

.resp-menu {
    display: none;
}



@media only screen and (max-width: 950px) {

    body {
        width: 100%;
        height: auto;

    }

    .nav-items {
        display: none;
        width: 30%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30px;
        font-size: 1.2rem;
        font-family: 'Raleway', 'Roboto';
        overflow: scroll;
        height: auto;
        position: absolute;
        z-index: 2;
        top: 80%;
        background-color: white;
        margin-right: 30px;
    }

    .nav-items a {
        margin-top: 20px;
    }

    .resp-menu {
        display: flex;
        width: 30%;
        height: auto;
        justify-content: end;
    }
}