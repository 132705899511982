.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1rem;
    font-family: 'Raleway', 'Roboto';
    padding: 10px;

}

.aboutus,
.ourvision,
.ourmission {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    justify-content: space-evenly;
    align-items: center;
}

.about img {
    width: 40%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.about-content,
.ourvision-content,
.ourmission-content {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1rem;
    font-family: 'Raleway', 'Roboto';
    padding: 10px;
}

.about-content-heading,
.ourvision-content-heading,
.ourmission-content-heading {
    font-size: 2.5rem;
    font-family: 'Raleway', 'Roboto';
    margin-bottom: 20px;
}

.industries-we-serve {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2rem;
    font-family: 'Raleway', 'Roboto';
    padding: 10px;
}

.industries-we-serve img {
    width: 80%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;

}

@media only screen and (max-width: 950px) {

    body {
        width: 100%;
        height: auto;

    }

    .about img {
        width: 90%;
        height: auto;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .aboutus,
    .ourvision,
    .ourmission {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .about-content,
    .ourvision-content,
    .ourmission-content {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        font-size: 1rem;
        font-family: 'Raleway', 'Roboto';
        padding: 0px;
    }

}