.App {
  text-align: center;
}

.container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
