

.seeker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.job-seeker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FE5967;
    width: 100%;
    margin: 20px;
    margin-left: 20px;
    padding: 20px;
    height: 200px;
    margin-bottom: 0px;
}

.job-seeker-title {
    font-size: 50px;
    font-family: 'Raleway', 'Roboto';
    font-weight: bold;
    color: white;
}

.job-seeker-content {
    color: white;
    font-size: 20px;
}

.seeker-form-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #ececec;
}

.seeker-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.seeker-form input {
    width: 70%;
    padding: 12px 20px;
    border-bottom: 1px solid #ccc;
    display: inline-block;
    box-sizing: border-box;
    background-color: #ececec;
}

.file-selection {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 20px;
}

.span-text {
    font-size: 15px;
    color: #808080;
}

.seeker-form-div img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 950px) {

    body {
        width: 100%;
        height: auto;

    }

    .seeker-form-div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        background-color: #ececec;
    }
}

