.contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.contact img {
    width: 50%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contactus-title {
    font-size: 2.5rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contactus-email {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-family: 'Raleway', 'Roboto';
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

@media only screen and (max-width: 950px) {

    body {
        width: 100%;
        height: auto;

    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .contact img {
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}